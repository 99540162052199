<template>
  <div>

    <van-overlay :show="show" >
      <!--    <div class="loading-wrap">
            <van-loading type="spinner" size="100" color="#f2733c" vertical>授权中</van-loading>
          </div>-->
      <div class="wrapper">
        <div class="block">
          <van-loading type="spinner" size="100"  vertical>授权中</van-loading>
        </div>
      </div>

    </van-overlay>


  </div>
</template>

<script>
import Vue from 'vue';
import {Loading, Overlay, Toast} from 'vant';
import {login, check} from '../api/user'
import wechat from "../utils/wechat";
import {clearUserToPath, getUserToPath, setAccessToken, getHrefUri} from "../utils/helper";

Vue.use(Loading);
Vue.use(Overlay);
Vue.use(Toast);
export default {
  name: "Oauth",
  data() {
    return {
      show: true
    }
  },

  created() {
    const {state, code} = this.$route.query
    const scope = state === 'STATE' ? 'snsapi_base' : 'snsapi_userinfo'
    // todo 处理授权出错情况
    login(code, scope).then(res=>{
      //console.log(res)
      if(res.code === '1001'){
        wechat.oauth('snsapi_userinfo', getUserToPath())
      }else if (res.code === '200'){
        setAccessToken(res.data.authorization)
        this.checkUser()
      }else {
        Toast.fail((res.message|| '用户授权失败'));
      }
    }, error => {
      console.log(error)
      Toast.fail('用户授权出错');
    })

  },
  methods:{
    checkUser(){
      check().then(res=>{
        //console.log(res)
        if(res.code === '200'){
          if(res.data.completed === 1){
            const path = getUserToPath()
            //console.log(path)
            clearUserToPath()
            //this.$router.push({path})
            window.location.href = getHrefUri(path)
          }else{
            wechat.oauth('snsapi_userinfo')
          }
        }else{
          Toast.fail((res.message|| '检查用户信息失败'));
        }
        // wechat.oauth('snsapi_userinfo')
      }, error => {
        console.log(error)
        Toast.fail('检查用户信息出错');
      })
    },

  }

}
</script>

<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 160px;
  height: 160px;
  /*background-color: #fff;*/
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

</style>
