import request from "../utils/axios"

export const login = (code, scope) => {
    return request({
        url: '/api/wechat/oauth/login',
        method: 'POST',
        data: {code, scope}
    })
}

export const check = () => {
    return request({
        url: '/api/user/info/check',
        method: 'POST'
    })
}
